import db from "@/firebase";

export const getTest = async (pageId, testId) =>
  db
    .collection("tests" + pageId)
    .doc(testId)
    .get();

export const testStats = async (pageId, testId) =>
  db
    .collection("tests" + pageId)
    .doc(testId)
    .collection("Stats")
    .doc("Score")
    .get();

export const testTakers = async (pageId, testId, userId) =>
  db
    .collection("tests" + pageId)
    .doc(testId)
    .collection("test_takers")
    .doc(userId)
    .get();


export const questions = async (pageId, testId) =>
  db
    .collection("tests" + pageId)
    .doc(testId)
    .collection("questions")
    .get();

export const revision = async () => db.collection("revisions").get();

export const getTestReference = pageId => db.collection("tests" + pageId);

export const getUserDetails = async uid =>
  db
    .collection("user_details")
    .doc(uid)
    .get();

export const getGoogleDocLinks = async userId =>
  db
    .collection("google-doc-links")
    .doc(userId)
    .get();

export const getPastPerformance = async userId =>
  db
    .collection("past_performance")
    .doc(userId)
    .get();

export const getFeedback = async (fieldPath, directionStr, limit) =>
  db
    .collection("feedback")
    .orderBy(fieldPath, directionStr)
    .limit(limit)
    .get();

export const getPercentageBlockColor = percent => {
  if (percent >= 75) return "#069E28";
  if (percent >= 55) return "#04FF00";
  if (percent >= 25) return "#CCEE0C";
  if (percent >= 15) return "#FF8000";
  return "#FF0000";
};

export const PIXEL_RATIO = window.devicePixelRatio || 1;
export const VIEWPORT_RATIO = 0.98;
